import React from "react";
import YouTube from "react-youtube";
import "../styles/LoveLetter.css";

function LoveLetter() {
  const opts = {
    playerVars: {
      // Set preferred video quality. Options: 'default', 'small', 'medium', 'large', 'hd720', 'hd1080', 'highres', 'auto'.
      "hd": 1,
      "rel": 0,
      "modestbranding": 1,
      "fs": 0,
      "disablekb": 0,
      "showinfo": 0,
      "iv_load_policy": 3
    },
  };

  return (
    <main>
      <div className="video--container">
        <YouTube videoId="E9wfjs32SMY" opts={opts}></YouTube>
      </div>
    </main>
  );
}

export default LoveLetter;
