import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/Videos.css";
import Video from "./ui/Video";
import fetchVideos from "../lib/fetchYoutubeVideos";

function Videos() {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    async function Init() {
      const response = await fetchVideos(5);
      setVideos(response.data.items);
    }

    Init();
  }, []);

  return (
    <section id="videos">
      <div className="videos__top--info">
        <p className="videos__top--para">
          <b className="videos__top--bold"> THE VERTIGOES</b> find themselves in
          a sensual and nostalgic Nordic Noir soundscape, with a dramatic and
          captivating melancholy
        </p>
      </div>
      <div className="videos--container">
        {videos.map((video) => (
          <Video
            key={video.id}
            title={video.snippet.title.replace("The Vertigoes - ", "")}
            img={video.snippet.thumbnails.standard.url}
            date="30. Jun. 2023"
            link={`https://www.youtube.com/watch?v=${video.snippet.resourceId.videoId}`}
          />
        ))}
        <Link to="/videos" className="videos__see-more">
          See More
        </Link>
      </div>
    </section>
  );
}

export default Videos;
