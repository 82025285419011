import React, { useEffect, useState } from "react";
import VideosList from "../components/VideosList";
import YTvideo from "../components/YTvideo";
import fetchVideos from "../lib/fetchYoutubeVideos.js";

function Videos() {
  const [videoId, setVideoId] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    async function fetchData() {
      const response = await fetchVideos(50)
      setData(response.data)
      setVideoId(response.data.items[0].snippet.resourceId.videoId)
    }
    fetchData()
  }, []);

  function selectVideo(Id, videoId) {
    setVideoId(videoId);
    let elements = document.getElementsByClassName("video__item");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.backgroundColor = "#0e0e0e";
    }

    document.getElementById(Id).style.backgroundColor = "#303030";
  }

  return (
    <main>
      <YTvideo key={videoId} videoId={videoId} />
      {data ? (
        <VideosList selectVideo={selectVideo} data={data} />
      ) : (
        <></>
      )}
    </main>
  );
}

export default Videos;
