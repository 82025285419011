import axios from "axios";

// UUDOHnpgzryDX4JX0IJuA_tw
const fetchVideos = async (maxResults) => {
  const response = await axios.get(
    `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet%2C%20contentDetails&maxResults=${maxResults}&playlistId=UUDOHnpgzryDX4JX0IJuA_tw&key=AIzaSyCqJXivcBCuHH7AqDGxkY7n5NjYRUEWmwQ`
  );
  return response;
};

export default fetchVideos;
