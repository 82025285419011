import React, { useState } from "react";
import "../../styles/Slideshow.css";

function SlideUI({ slide }) {
  const [loading, setLoading] = useState(true);

  return (
    <div className="each-slide-effect">
      <div
        className="image__slider"
      >
        <img src={slide.image} className="image__img" alt="" />
        <div className={`image__info image__info--${slide.id}`}>
          <h1
            className={`image__quote image__quote--${slide.id}`}
          >{`"${slide.quote}"`}</h1>
          <h3 className={`image__person image__person--${slide.id}`}>
            {slide.person}
          </h3>
        </div>
      </div>
    </div>
  );
}

export default SlideUI;
