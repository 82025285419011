import React from "react";
import "../styles/VideosList.css";
import VideoListItem from "./ui/VideoListItem.jsx";

function VideosList({ selectVideo, data }) {
  return (
    <>
      <div className="videos__list--container">
        {data.items.map((video) => (
          <VideoListItem
            video={video}
            selectVideo={selectVideo}
            key={video.id}
          />
        ))}
      </div>
    </>
  );
}

export default VideosList;
