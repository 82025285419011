import React from "react";
import "../styles/Copyright.css"

function Copyright() {
  return (
    <div className="copyright--container">
      <h1 className="copyright__text">Copyright &#169; The Vertigoes 2023</h1>
    </div>
  );
}

export default Copyright;
