import React, { useEffect, useState } from "react";
import formatDate from "../../lib/formatData";

function VideoListItem({ video, selectVideo }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = video.snippet.thumbnails.high.url;

    const handleImageLoad = () => {
      setImageLoaded(true);
    };

    image.onload = handleImageLoad;

    return () => {
      image.onload = null;
    };
  }, [video.snippet.thumbnails.high.url]);

  function goTop(id, videoId) {
    selectVideo(id, videoId);
    setTimeout(() => {
      window.scrollTo({ top: 180, behavior: "smooth" });
    }, 200);
  }

  return (
    <button
      onClick={() => goTop(video.id, video.snippet.resourceId.videoId)}
      id={video.id}
      className="video__item"
    >
      {!imageLoaded ? (
        <>
          <figure className="video__item__img--wrapper">
            <div className="video__item__img video__item__img--loading"></div>
          </figure>
          <div className="video__item__info">
            <div className="video__item__info-top">
              <div className="video__item__title video__item__title--loading"></div>
              <div className="video__item__creator--loading video__item__creator"></div>
            </div>
            <div className="video__item__date video__item__date--loading"></div>
          </div>
        </>
      ) : (
        <>
          <figure className="video__item__img--wrapper">
            <img src={video.snippet.thumbnails.high.url} alt="" className="video__item__img" />
          </figure>
          <div className="video__item__info">
            <div className="video__item__info-top">
              <h1 className="video__item__title">{(video.snippet.title).replace("The Vertigoes - ", "")}</h1>
              <h2 className="video__item__creator">{video.snippet.channelTitle}</h2>
            </div>
            <h2 className="video__item__date">{formatDate(video.snippet.publishedAt)}</h2>
          </div>
        </>
      )}
    </button>
  );
}

export default VideoListItem;
