import React from "react";
import Slideshow from "../components/Slideshow.jsx";
import Videos from "../components/Videos.jsx";

function Landing() {
  return (
    <main className="slideshow--container">
      <Slideshow />
      <Videos />
    </main>
  );
}

export default Landing;
